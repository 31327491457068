export const aboutMeData = {
  websiteDescPl1: "Hej, jestem Damian, a to jest moja strona portfolio.",
  websiteDescPl2:
    "Jeśli jesteś zainteresowany moimi projektami, śmiało skontaktuj ze mną.",
  websiteDescEn1: "Hey, I’m Damian and it’s my portfolio website.",
  websiteDescEn2:
    "If you are interested in my projects, feel free to contact me.",
    titlePl: "UMIEJĘTNOŚCI:",
    titleEn: "ABILITIES:"
};
